.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.item {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Add smooth transition for hover effects */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.item:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.item h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  text-align: center;
}

.item-content {
  display: flex;
  align-items: center;
}

.item img {
  width: 150px;
  height: auto;
  border-radius: 4px;
  margin-right: 15px;
}

.item p {
  flex: 1;
  margin: 0;
  color: #666;
}

@media (max-width: 768px) {
  .item {
    width: 100%;
  }
  
  .item-content {
    flex-direction: column;
  }
  
  .item img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

body {
  padding: 0 10px;
}