.home-page {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: relative;
}

.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.0em;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.nav-menu {
  background-color: #333;
}

.menu-level-1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-level-1 > li {
  position: relative;
}

.menu-level-1 > li > a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.menu-level-1 > li > a:hover {
  background-color: #111;
}


.main-content {
  flex: 1 0 auto;
}

.content-blocks {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.block {
  width: 30%;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}

.block-content {
  background-color: #f0f0f0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.block-content:hover {
  transform: translateY(-10px);
}

.block img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}


/* Featured block styles */
.featured-block .block-content {
  background-color: #e0e0e0;
  border: 2px solid #007bff;
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.featured-block p {
  color: #007bff;
}

/* Hover effects */
.block:hover {
  transform: translateY(-5px);
}

.featured-block:hover {
  transform: translateY(-5px) scale(1.05);
}

.featured-block {
  position: relative;
}

.star-icon {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffd700; /* Gold color for the star */
  color: #fff;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.featured-block .block-content {
  border-top: 3px solid #ffd700; /* Gold border to match the star */
  padding-top: 20px; /* Add some space for the star */
}


.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 5px 0;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .block {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .block {
    width: 100%;
  }
}