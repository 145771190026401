.coming-soon-container {
    background-color: #f0f8ff;
    border: 2px solid #4682b4;
    border-radius: 10px;
    padding: 20px;
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .coming-soon-header {
    color: #4682b4;
    font-size: 28px;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .coming-soon-message {
    color: #333;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .coming-soon-container::before {
    content: "\1F30E";
    font-size: 48px;
    display: block;
    margin-bottom: 10px;
  }