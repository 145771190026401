body {
  font-family: Arial, sans-serif;
  line-height: 1;
  color: #333;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #f0f0f0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1px;
  margin-bottom: 1px;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.park-info p{
  margin-bottom:  10px;
}

ul, ol {
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: 20px;
}

li {
  margin-bottom: 1px;
}

a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.content-display {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.main-title {
  font-size: 2.5em;
  color: #333;
}

.main-description {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.day-block {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-family: Arial, sans-serif;
}

.day-block h2 {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

day-block ul,
.day-block ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

.day-block li {
  margin-bottom: 5px;
  line-height: 1.5;
}

.day-block li > ul,
.day-block li > ol {
  margin-top: 5px;
  margin-bottom: 5px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  margin-top: 15px;
}

.image-gallery img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.common-block {
  background-color: #e8f4f8;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-family: Arial, sans-serif;
}

.common-block h2 {
  color: #2980b9;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.travel-log,
.day-itinerary,
.park-entry-info,
.accommodation-info,
.dining-guide,
.packing-list,
.park-info {
  margin-bottom: 1px;
}

.travel-log h2,
.day-itinerary h2,
.park-entry-info h2,
.accommodation-info h2,
.dining-guide h2,
.park-info h2 {
  border-bottom: 1px solid #333;
  padding-bottom: 3px;
  margin-bottom: 1px;
}

.park-entry-info ul,
.accommodation-info ol,
.dining-guide ul {
  margin-bottom: 1px;
}

.accommodation-info ol > li {
  margin-bottom: 1px;
}

.dining-guide h3 {
  margin-top: 1px;
  margin-bottom: 1px;
  color: #0066cc;
}

.dining-guide > ul {
  margin-top: 1px;
  margin-bottom: 1px;
}

.dining-guide > ul > li {
  margin-bottom: 1px;
}

.dining-guide > ul > li:last-child {
  margin-bottom: 1px;
}

.dining-guide ul ul {
  margin-top: 1px;
  margin-bottom: 1px;
}

.dining-guide ul ul li {
  margin-bottom: 1px;
}

.packing-list ol {
  list-style-type: disc;
  margin-bottom: 0.5rem;
  padding-left: 20px;
}

.packing-list li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 50%;
}

.image-container img{
  width: 100%;
  height: 110%;
  object-fit: cover;
  object-position: center;
}

.main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.image-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
}

.place-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.strong {
  font-weight: bold;
}

.emphasis {
  font-style: italic;
}

.image-block-carousel {
  position: relative;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 10px 0;
}

.carousel::-webkit-scrollbar { 
  display: none;
}

.carousel-item {
  flex: 0 0 auto;
  width: 200px;
  margin-right: 20px;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.carousel-title {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  max-width: 100%;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
  border-radius: 50%;
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}

.carousel-image.clickable {
  pointer-events: auto !important;
  cursor: pointer !important;
}

.clickable {
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.popup-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.popup-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.popup-nav.prev {
  left: 10px;
}

.popup-nav.next {
  right: 10px;
}

@media (max-width: 600px) {
  body {
    padding: 5px;
  }

  .image-title {
    font-size: 1.2rem;
  }

  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .image-gallery img {
    height: 150px;
  }

  .dining-guide p{
    margin-bottom:  1px;
  }

  .carousel-item {
    width: 150px;
  }

  .carousel-image {
    height: 100px;
  }

  .carousel-title {
    font-size: 12px;
  }

  .popup-content {
    width: 95%;
  }

  .popup-nav {
    padding: 5px;
    font-size: 16px;
  }
}